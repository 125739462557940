/**
 * Created by gschulz on 06.06.14.
 */
let Organisation = (function () {

    let _private = {};

    _private.form = $();

    this.init = () => {
        let form      = $('form.organisation');
        _private.form = form;
        new FormObserver().init(form).setSaveCallBack('saveForm', () => {
            _private.mapSection.beforeSave();
            new Abstract().saveForm(_private.form, () => {this.init()});
        });
        //new EfbHelperForm().initFloatValElements(form);
        _private.mapSection = new OrganisationMapSection().init(form);
        _private.mapWeather = new OrganisationWeatherSection().init(form);
        EfbHelper.initToggler(form);
        _private.initWeather();
        _private.initCoordinateSystems();
    };

    _private.initCoordinateSystems = () => {
        const container = _private.form.find('fieldset.coordinate-systems');
        const table     = container.find('div.form-table');
        container.find('a.button').unbind('click.addLine').on('click.addLine', () => {
            const count   = table.find('div.form-table-line').length
            const newLine = $.tmpl($('#coordinate-system'), {});
            const ta      = newLine.find('textarea');
            ta.attr('id', ta.attr('id').replace(/\d+/g, count));
            const value = ta.val();
            ta.val(value.replace(/,/g, ',\n').replace(/}$/g, '\n}').replace(/^{/g, '{\n'))
            newLine.addClass('active');
            table.append(newLine);
            _private.form.data('FormObserver').addElement(ta);
            newLine.find('a.toggler').click();
        });
        const initCodeMirror = line => {
            const ta = line.find('textarea');
            if (ta.data('CodeMirrorInstance')) {
                return;
            }
            const instance = CodeMirror.fromTextArea(ta.get(0), {
                lineNumbers:  true,
                lineWrapping: true,
                mode:         "application/json",
                gutters:      ["CodeMirror-lint-markers"],
                lint:         true
            });
            instance.setSize(null, 210);
            ta.data('CodeMirrorInstance', instance);
            const onInstanceChange = instance => {
                let data                = null;
                let errors              = [];
                const ta                = $(instance.getTextArea());
                const value             = instance.getValue();
                const propertyValidator = new PropertyValidator({data: properties});
                try {
                    data = JSON.parse(value);
                    propertyValidator.validateObject(properties.coordinate_system.structure, data, []);
                    const result = propertyValidator.prepareResult();
                    errors.push(...result.errors);
                } catch (e) {
                    errors.push({msg: 'Invalid JSON'});
                }
                line.find('ul.error-info').empty();
                Validator.removeErrors(ta);
                if (errors.length) {
                    Validator.addError(ta, Validator.NsInvalidJson);
                    errors.forEach(e => {
                        line.find('ul.error-info').append($('<li/>').text(e.msg))
                    });
                }
                data = data || {};
                line.find('a.toggler').text(data.name && typeof data.name === 'string' ? data.name : '-');
                ta.val(value).blur();
            }
            instance.on("change", onInstanceChange);
            onInstanceChange(instance);
        }
        container.unbind('click').on('click', 'a.toggler', e => {
            const trigger = $(e.target).toggleClass('open');
            const line    = trigger.parent();
            trigger.next().toggle(trigger.hasClass('open'));
            initCodeMirror(line);
        })
    };

    _private.initWeather = () => {
        let textarea = _private.form.find('textarea[name=weather]');
        if (!textarea.length) {
            return;
        }
        let codeMirrorInstance = $(textarea).data('CodeMirrorInstance');
        if (!_.isUndefined(codeMirrorInstance)) {
            codeMirrorInstance.refresh();
            return;
        }
        codeMirrorInstance = CodeMirror.fromTextArea(textarea.get(0), {
            lineNumbers: true,
            mode:        "application/json",
            gutters:     ["CodeMirror-lint-markers"],
            lint:        true
        });
        codeMirrorInstance.setSize(textarea.width());
        codeMirrorInstance.on("change", instance => {
            $(instance.getTextArea()).unbind('keyup.validateJson').on('keyup.validateJson',
                event => {
                    Validator.validateJson($(event.target))
                }
            );
            $(instance.getTextArea()).val(instance.getValue()).keyup();
        });
        $(textarea).data('CodeMirrorInstance', codeMirrorInstance);
        _private.form.find('div.section.weather').hide();
    };


    _private.reorderWeatherItems = weatherItems => {
        $(weatherItems).each((i, waypoint) => {
            $(waypoint).find('input').each((l, inp) => {
                $(inp).attr('id', $(inp).attr('id').replace(/\d+/g, i));
                $(inp).attr('name', $(inp).attr('name').replace(/\d+/g, i));
            });
            $(waypoint).find('label').each((l, label) => {
                $(label).attr('for', $(label).attr('for').replace(/\d+/g, i));
            });
        });
    };
});